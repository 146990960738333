import React from "react";

import usePersistedState from "../hooks/usePersistedState";

interface ITokenContext {
  expiredToken: Date;
  setExpireTokenDate: (date: Date) => void;
}

export const TokenContext = React.createContext<ITokenContext>(
  {} as ITokenContext
);

export const TokenContextProvider = ({ children }: any) => {
  function getCurrentDateWithTenMinutes() {
    const currentDate = new Date();
    const tenMinutes = 5 * 60 * 1000;

    return new Date(currentDate.getTime() + tenMinutes);
  }

  const [expiredToken, setExpireTokenDate] = usePersistedState<Date>(
    "expireToken",
    getCurrentDateWithTenMinutes()
  );

  function verifyIfExpiredTokenDate(date: Date) {
    const currentDate = new Date();
    const expireDate = new Date(date);
    return currentDate > expireDate;
  }

  React.useEffect(() => {
    const value = verifyIfExpiredTokenDate(expiredToken);
    if (value) {
      localStorage.removeItem("expireToken");
      localStorage.removeItem("picLoad");
      localStorage.removeItem("homeAnimationLoaded");
      window.location.reload();
    }
  }, [expiredToken]);

  return (
    <TokenContext.Provider value={{ expiredToken, setExpireTokenDate }}>
      {children}
    </TokenContext.Provider>
  );
};
