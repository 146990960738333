import styled from "styled-components";
import { fadeInAnimation } from "../../styles/global";

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
  border-radius: 50%;
  border: 2px solid
    ${({ theme }) =>
      `linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.secondary})`};

  box-shadow: rgba(45, 47, 49, 0.2) 0px 8px 24px;

  background: ${({ theme }) => theme.colors.text};
  display: flex;

  justify-content: center;
  align-items: center;

  opacity: 0;
  animation: ${fadeInAnimation} 0.5s ease forwards;
`;

export const Container = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 2px solid
    ${({ theme }) =>
      `linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.secondary})`};

  box-shadow: rgba(45, 47, 49, 0.2) 0px 8px 24px;

  opacity: 0;
  animation: ${fadeInAnimation} 0.5s ease forwards;

  &:nth-child() {
    animation: ${fadeInAnimation} 0.5s 0.1s ease forwards;
  }

  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colors.primary}, ${theme.colors.secondary})`};
  display: flex;
`;
