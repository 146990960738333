import React from "react";

import Lottie from "react-lottie";

import animationDataLight from "../json/loading-light.json";
import animationDataDark from "../json/loading-dark.json";

import { ThemeContext } from "../../../context/ThemeSwitcherContext";

export default function NotFoundAnimation() {
  const { isDark } = React.useContext(ThemeContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: isDark ? animationDataDark : animationDataLight,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie isClickToPauseDisabled options={defaultOptions} height={400} width={400} />;
}
