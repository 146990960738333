import { useLanguage } from "context/LanguageContext";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ThemeContext } from "../../context/ThemeSwitcherContext";
import usePersistedState from "../../hooks/usePersistedState";

import { Container, Svg } from "./styles";
import SwitchThemeButton from "./SwitchThemeButton";

const Header = () => {
  const [loading, setLoading] = usePersistedState("headerLoading", true);
  const { isDark, toggleTheme } = React.useContext(ThemeContext);
  const { pathname } = useLocation();
  const { redirectByLang } = useLanguage();

  const navigate = useNavigate();

  setTimeout(() => {
    setLoading(false);
  }, 5300);

  const verifyIfIsHome = () => {
    if (pathname === "/home" || pathname === "/inicio") {
      return true;
    }
    return false;
  };

  const homePage = verifyIfIsHome();

  if (loading) {
    return (
      <Container>
        <div />
        <div />
      </Container>
    );
  }

  if (homePage) {
    return (
      <Container>
        <div />
        <SwitchThemeButton checked={isDark} onClick={() => toggleTheme()} />
      </Container>
    );
  }

  return (
    <Container>
      <button onClick={() => navigate(redirectByLang)}>
        <Svg width="43" height="41" viewBox="0 0 43 41" fill="none">
          <path
            d="M42.1145 20.1589L22.7423 0.800865C22.6028 0.661107 22.4371 0.550229 22.2547 0.474577C22.0723 0.398926 21.8768 0.359985 21.6794 0.359985C21.4819 0.359985 21.2864 0.398926 21.104 0.474577C20.9216 0.550229 20.756 0.661107 20.6165 0.800865L1.24429 20.1589C0.67992 20.7233 0.360107 21.4899 0.360107 22.2894C0.360107 23.9496 1.7099 25.2994 3.37011 25.2994H5.41126V39.1125C5.41126 39.945 6.08381 40.6175 6.91626 40.6175H18.6694V30.0825H23.9369V40.6175H36.4425C37.2749 40.6175 37.9475 39.945 37.9475 39.1125V25.2994H39.9886C40.7882 25.2994 41.5548 24.9843 42.1192 24.4153C43.2902 23.2395 43.2902 21.3347 42.1145 20.1589Z"
            fill="black"
            fillOpacity="0.57"
          />
        </Svg>
      </button>

      <SwitchThemeButton checked={isDark} onClick={() => toggleTheme()} />
    </Container>
  );
};

export default Header;
