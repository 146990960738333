import styled from "styled-components";
import { fadeInAnimation } from "../../styles/global";

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  z-index: 1000;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &:nth-child() {
    opacity: 0;
    animation: ${fadeInAnimation} 0.5s ease forwards;
  }
`;
