export const lang = {
  id: 'pt-br',
  home: {
    path: '/inicio',
    title: {
      colored: 'Front-end',
      text: 'Developer'
    },
    buttons: {
      changedLang: 'English',
      about: 'Sobre',
      contact: 'Contato',
      projects: 'Projetos'
    }
  },
  about: {
    path: '/sobre',
    title: "Estudante de Computação apaixonado por técnologia !",
    subtitle: 'Busco sempre entregar o melhor conteúdo focado na melhor experiência possível para o usuário !',
  },
  notFound: {
    title: 'Página não encontrada',
  }
} 
