import React, { useState } from "react";
import { ILang, ILanguages } from "types/langagues";
import { ptBr, enUs } from "langs";

interface LanguageContextProps {
  language: ILang;
  redirectByLang: string;
  languageSelected: ILanguages;
  setLanguageSelected: Function;
}

const LanguageContext = React.createContext<LanguageContextProps>(
  {} as LanguageContextProps
);

const LanguageContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isFirstRender = React.useRef(true);

  const [languageSelected, setLanguageSelected] = useState<ILanguages>("pt-br");
  const [language, setLanguage] = useState<ILang>(ptBr);

  const handleRedirectByLang = () => {
    if (languageSelected === "pt-br") {
      return "/inicio";
    }
    return "/home";
  };

  const redirectByLang = handleRedirectByLang();

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      const savedLang = window.localStorage.getItem("lang");
      if (savedLang) {
        setLanguageSelected(savedLang as ILanguages);
      } else {
        setLanguageSelected("pt-br");
        window.localStorage.setItem("lang", languageSelected);
      }
    } else {
      window.localStorage.setItem("lang", languageSelected);

      if (languageSelected === "pt-br") {
        setLanguage(ptBr);
      } else {
        setLanguage(enUs);
      }
    }
  }, [languageSelected]);

  console.log(language)

  return (
    <LanguageContext.Provider value={{ languageSelected, setLanguageSelected, language, redirectByLang }}>
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => React.useContext(LanguageContext);

export { LanguageContextProvider, useLanguage, LanguageContext };
