import React from "react";

import { ToggleButton } from "./styles";

interface ISwitchThemeButton {
  checked: boolean;
  onClick: () => void;
}

const SwitchThemeButton = ({ checked, onClick }: ISwitchThemeButton) => {
  return (
    <ToggleButton isDark={checked}>
      <div className="toggle-btn" id="_1st-toggle-btn">
        <input onChange={() => onClick()} checked={checked} type="checkbox" />
        <span></span>
      </div>
    </ToggleButton>
  );
};

export default SwitchThemeButton;
