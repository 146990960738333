import React from "react";

import GlobalStyle from "./styles/global";

import { ThemeProvider } from "styled-components";
import { ThemeContext } from "./context/ThemeSwitcherContext";

import Loading from "./pages/Loading";
import RouterComponent from "./Router";

import { useLoading } from "./hooks/useLoading";

function App() {
  const { theme } = React.useContext(ThemeContext);
  const { loading } = useLoading();

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Loading />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <RouterComponent />
    </ThemeProvider>
  );
}

export default App;
