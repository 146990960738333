import { createGlobalStyle, keyframes } from "styled-components";

export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  &::selection {
    color: white;
    background: ${({ theme }) => theme.colors.primary};
  }

  &::-moz-selection {
    background: ${({ theme }) => theme.colors.primary};
  }

  body{
    transition: 0.3s;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    font-smooth: always;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    background: ${({ theme }) => theme.colors.background};

    @media (max-width: 1080px) {
       font-size: 90.75%;
     }

     @media (max-width: 720px) {
       font-size: 85.5%;
     }
  }



`;
