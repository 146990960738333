import React from "react";
import Pic from "../../assets/animations/components/Pic";

import { Container, LoadingContainer } from "./styles";
import usePersistedState from "../../hooks/usePersistedState";

const ProfileCircle = () => {
  const [loading, setLoading] = usePersistedState("picLoad", true);

  const githubPick = `https://avatars.githubusercontent.com/4lysson-a`;

  setTimeout(() => {
    setLoading(false);
  }, 3500);

  if (loading) {
    return (
      <LoadingContainer>
        <Pic />
      </LoadingContainer>
    );
  }

  return <Container alt="minha imagem de perfil do github" src={githubPick} />;
};

export default ProfileCircle;
