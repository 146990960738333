import { useContext } from "react";
import { LoadingContext } from "../context/LoadingContext";

function useLoading() {
  const { loading, setLoading } = useContext(LoadingContext);

  return {
    loading,
    setLoading,
  };
}

export { useLoading };
