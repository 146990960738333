import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  transform: translate(-50%, -45%);
  top: 50%;
  left: 50%;

  z-index: -10;

  height: 100%;
  width: 100%;

  font-weight: bolder;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    padding: 10px;
    opacity: 0.8;
    font-size: 3rem;
  }
`;
