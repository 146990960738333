import styled, { css, keyframes } from "styled-components";
import { fadeInAnimation } from "../../styles/global";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeText = keyframes`
  0% {
    width: 0%;
    bottom: 0;  
    z-index: 1;
    opacity: 0;
    height: 100%;
    place-content: center;
    transform: translate(0, 10px);
    transform-origin: bottom right;
  }
  50% {
    transform: translate(0, 0);
    opacity: 1;
    z-index: 1;
    height: 100%;
    width: 100%;
    bottom: 0;
  }
  99% {
    transform: translate(100%, 0);
    opacity: 1;  
    height: 100%;
    z-index: 1;
    bottom: 0;
  }
  100% {
    transform: translate(100%, 0);
    opacity: 1;  
    height: 100%;
    z-index: -1;
    bottom: 0;
  }
  `;

export const ChangeLanguage = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  z-index: 1;

  @media (max-height: 730px) {
    left: 1;
    top: 0;
  }

  button {
    background: none;
    opacity: 0.6;
    font-family: ${({ theme }) => theme.fonts.monospaced};
    font-size: 1rem;
    font-weight: bold;
    border: none;
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    text-decoration: underline;

    @media (max-width: 330px) {
      font-size: 0.8rem;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.text};
      opacity: 0.4;

      &:hover {
        opacity: 0.4;
      }
    }

    &:hover {
      opacity: 1;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: left;

  margin-top: 20px;

  .div-1 {
    opacity: 0;
    animation: ${fadeInAnimation} 0.5s 0.1s ease forwards;
  }

  .div-2 {
    opacity: 0;
    animation: ${fadeInAnimation} 0.5s 0.2s ease forwards;
    svg {
      width: 34px;
      path {
        size: 30px;
        width: 33px;
        font-size: 30px;
      }
    }
  }

  .div-3 {
    opacity: 0;
    animation: ${fadeInAnimation} 0.5s 0.3s ease forwards;
  }

  svg {
    transition: 0.3s;

    &:hover {
      opacity: 0.7;

      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
    cursor: pointer;

    width: 30px;
    path {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`;

const typingAnimation = keyframes`
   from {
    width: 0;
  } to {
    width: 22ch;
  }
`;

const blink = keyframes`
  50% {
    border-color: transparent
  }
`;

export const WrapperType = styled.div<{
  homeLoaded: boolean;
}>`
  margin-top: 15px;
  .typing-demo {
    width: ${({ homeLoaded }) => (homeLoaded ? "22ch" : "0")};

    animation: ${blink} 0.5s step-end infinite alternate;

    animation: ${({ homeLoaded }) =>
      !homeLoaded &&
      css`
        ${typingAnimation} 3s 2s steps(22) forwards,
      ${blink} 0.5s step-end infinite alternate;
      `};
    white-space: nowrap;
    overflow: hidden;
    border-right: 20px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 150px);
  padding: 0 50px;
`;

export const LeftContent = styled.div<{
  isMobile: boolean;
}>`
  gap: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    background: none;
    opacity: 0.6;
    font-family: ${({ theme }) => theme.fonts.monospaced};
    font-size: 1rem;
    font-weight: bold;
    border: none;
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    text-decoration: underline;

    @media (max-width: 330px) {
      font-size: 0.8rem;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.text};
      opacity: 0.4;

      &:hover {
        opacity: 0.4;
      }
    }

    &:hover {
      opacity: 1;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ul {
    justify-content: left;
    align-items: left;
    list-style: none;
    display: flex;
    gap: 20px;

    li {
      cursor: pointer;
    }
  }

  h1 {
    font-size: ${({ isMobile }) => (isMobile ? "3rem" : "5rem")};

    @media (max-width: 330px) {
      font-size: 2rem;
    }
  }

  h2 {
    span {
      color: ${({ theme }) => theme.colors.primary};
    }

    @media (max-width: 330px) {
      font-size: 1rem;
    }

    font-size: ${({ isMobile }) => (isMobile ? "1.3rem" : "3rem")};
    font-family: ${({ theme }) => theme.fonts.monospaced};
    opacity: 0.8;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 70px;

  div {
    opacity: 0;
    animation: ${fadeInAnimation} 0.5s 0.1s ease forwards;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Title = styled.div<{
  homeLoaded: boolean;
}>`
  font-family: ${({ theme }) => theme.fonts.monospaced};
  font-weight: 1000;
  position: relative;
  font-size: 5rem;

  overflow: hidden;

  h1 {
    #alysson {
      opacity: ${({ homeLoaded }) => !homeLoaded && css`0`};
      animation: ${({ homeLoaded }) =>
        !homeLoaded &&
        css`
          ${fadeIn} 0.3s 1.4s ease forwards
        `};
    }

    #alcantara {
      opacity: ${({ homeLoaded }) => !homeLoaded && css`0`};
      animation: ${({ homeLoaded }) =>
        !homeLoaded &&
        css`
          ${fadeIn} 0.3s 1.55s ease forwards
        `};
    }
  }

  div {
    height: 100%;
    opacity: 0;

    display: ${({ homeLoaded }) => homeLoaded && css`none`};

    animation: ${({ homeLoaded }) =>
      !homeLoaded &&
      css`
        ${fadeText} 2s 0.3s ease forwards
      `};
    z-index: -1;
    bottom: 20px;
    border-radius: 2px;
    position: absolute;
    background: ${({ theme }) => theme.colors.gradient};
    width: 100%;
  }
`;
