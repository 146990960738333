import React from "react";

import NotFoundAnimation from "../../assets/animations/components/NotFoundAnimation";

import { Container } from "./styles";

const NotFound = () => {
  return (
    <Container>
      <NotFoundAnimation />
      <h1>Página não encontrada</h1>
    </Container>
  );
};

export default NotFound;
