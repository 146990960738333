import { keyframes } from "styled-components";

export const animationTime = 2;

export const shimmerAnimationLight = keyframes`
  0% {
      background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
      background-size: 400% 400%;
      background-position: 0% 0%;
    }
  50% {
    background-position: -135% 0%;
  }
  100%{
    background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
    background-size: 400% 400%;
    background-position: 0% 0%;
  }
`;

export const shimmerAnimationBlack = keyframes`
  0% {
      background: linear-gradient(-90deg, #2c2e30 0%, #1c1b1b 50%, #2c2e30 100%);
      background-size: 400% 400%;
      background-position: 0% 0%;
    }
  50% {
    background-position: -135% 0%;
  }
  100%{
    background: linear-gradient(-90deg, #2c2e30 0%, #1c1b1b 50%, #2c2e30 100%);
    background-size: 400% 400%;
    background-position: 0% 0%;
  }
`;
