import React from "react";
import { ButtonContainer, Circle, Line } from "./styles";

const ContactButton = () => {
  const handleSendMail = () => {
    let link = document.createElement("a");
    link.setAttribute("href", "mailto:4ly.alcantara@gmail.com");
    link.click();
  };

  return (
    <ButtonContainer onClick={() => handleSendMail()}>
      <Line className="line" />
      <p>Contact Me</p>
      <Circle>
        <svg
          width="42"
          height="42"
          viewBox="0 0 42 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            id="circle"
            cx="21"
            cy="21"
            r="21"
            fill="url(#paint0_linear_15_13)"
          />
          <path
            className="arrow"
            d="M30.625 21L11.375 21M30.625 21L26.3472 16.7222M30.625 21L26.3472 25.2778"
            stroke="white"
            strokeWidth="3.23889"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_15_13"
              x1="2.37682e-10"
              y1="22.75"
              x2="59.0625"
              y2="21"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#452AEF" />
              <stop offset="1" stopColor="#5FA5F8" />
              <stop offset="1" stopColor="#5F80F8" />
            </linearGradient>
          </defs>
        </svg>
      </Circle>
    </ButtonContainer>
  );
};

export default ContactButton;
