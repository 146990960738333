import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from{
    opacity: 0;
    transform: rotate(90deg) translateX(50px) scale(0.5);
  } 50% {
    opacity: 1;
    transform: rotate(90deg) translateX(0px) scale(0.5);
  } 100% {
    opacity: 1;
    transform: rotate(0deg) translateX(0px) scale(0.5);
  }
`;

export const ToggleButton = styled.div<{
  isDark: boolean;
}>`
  transform: scale(0.5);
  margin-right: -30px;

  opacity: 0;
  animation: ${fadeIn} 1.3s 0.4s ease-in-out forwards;

  section {
    width: 250px;
    margin: 100px auto;
    padding: 60px;
    background-color: #fff;
    box-shadow: ${({ isDark }) => !isDark && "0px 5px 35px #d7d7d7"};
    border-radius: 35px;
  }

  .toggle-btn {
    position: relative;
    width: 145px;
    height: 74px;
    margin: 0 auto;
    border-radius: 40px;
  }

  input[type="checkbox"] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  /* First toggle btn */

  #_1st-toggle-btn span {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    opacity: 1;
    background-color: #fff;
    box-shadow: ${({ isDark }) => !isDark && "0px 2px 25px #d9d9d9"};
    box-shadow: ${({ isDark }) => isDark && "0px 2px 5px #333333"};
    border-radius: 40px;
    transition: 0.2s ease background-color, 0.2s ease opacity;
  }

  #_1st-toggle-btn span:before,
  #_1st-toggle-btn span:after {
    content: "";
    position: absolute;
    top: 8px;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    transition: 0.5s ease transform, 0.2s ease background-color;
  }

  #_1st-toggle-btn span:before {
    background-color: #fff;
    transform: translate(-58px, 0px);
    z-index: 1;
  }

  #_1st-toggle-btn span:after {
    background-color: ${({ theme }) => theme.colors.primary};
    transform: translate(8px, 0px);
    z-index: 0;
  }

  #_1st-toggle-btn input[type="checkbox"]:checked + span {
    background-color: #000;
  }

  #_1st-toggle-btn input[type="checkbox"]:active + span {
    opacity: 0.5;
  }

  #_1st-toggle-btn input[type="checkbox"]:checked + span:before {
    background-color: #000;
    transform: translate(56px, -19px);
  }

  #_1st-toggle-btn input[type="checkbox"]:checked + span:after {
    background-color: ${({ theme }) => theme.colors.primary};
    transform: translate(79px, 0px);
  }
`;
