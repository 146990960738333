import React from "react";
import styled from "styled-components";
import { shimmerAnimationBlack } from "../../../styles/animationShimmer";
import { fadeInAnimation } from "../../../styles/global";

const animationTime = "1.5s";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1vw;

  opacity: 0;
  animation: ${fadeInAnimation} 0.6s 0.15s ease forwards;

  .fist {
    opacity: 0;
    animation: ${fadeInAnimation} 0.6s 0.15s ease forwards,
      ${({ theme }) =>
    theme.title === "light"
      ? shimmerAnimationBlack
      : shimmerAnimationBlack}
        ${animationTime} infinite;
    width: 11vw;
    height: 60vh;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-top: 10vh;
  }

  .sec {
    opacity: 0;
    animation: ${fadeInAnimation} 0.6s 0.15s ease forwards,
      ${({ theme }) =>
    theme.title === "light"
      ? shimmerAnimationBlack
      : shimmerAnimationBlack}
        ${animationTime} infinite;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    object-fit: cover;
    width: 13vw;
    height: 60vh;
  }
`;

const LoadImageShimmer = () => {
  return (
    <Container>
      <div className="fist"></div>
      <div className="sec"></div>
    </Container>
  );
};

export default LoadImageShimmer;
