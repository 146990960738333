import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  height: 70vh;
  display: flex;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  gap: 50px;

  h1 {
    span {
      margin-left: 20px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  
  h2 {
    opacity: 0.8;
  }

  button {
    transition: 0.2s;
    cursor: pointer;
    margin-top: 50px;
    padding: 10px 5px;
    border-radius: 4px;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    background: transparent;

    h1 {
      font-size: 1.4rem;
      color: ${({ theme }) => theme.colors.text};
      font-weight: bold;
    }

    &:hover {
      transform: translateY(-5px);
      background: ${({ theme }) => theme.colors.primary};
    }
  }
`;

interface IShowVersion {
  version: string;
}

const ShowVersion = ({ version }: IShowVersion) => {
  const navigate = useNavigate();
  const handleResetApplication = () => {
    navigate("/");
    window.location.reload();
    window.localStorage.clear();
  };

  return (
    <Container>
      <Content>
        <div>
          <h1>Você achou um easter egg 🥚 !!</h1>
        </div>
        <br />
        <div>
          <h1>Meu app esta atualmente na</h1>
        </div>
        <div>
          <h1>
            versão: <span>{version}</span>
          </h1>
        </div>
        <br />
        <div>
          <h2>Se você fez cagada clica nesse botão ai que resolve</h2>
        </div>
        <button onClick={handleResetApplication}>
          <h1>Atualizar</h1>
        </button>
      </Content>
    </Container>
  );
};

export default ShowVersion;
