export const lang = {
  id: 'en-us',
  home: {
    path: '/home',
    title: {
      colored: 'Front-end',
      text: 'Developer'
    },
    buttons: {
      changedLang: 'Português',
      about: 'About',
      contact: 'Contact',
      projects: 'Projects'
    }
  },
  about: {
    path: '/about',
    title: "Computer student passionate about technology!",
    subtitle: 'I always seek to deliver the best content focused on the best possible experience for the user!',
  },
  notFound: {
    title: "oops you weren't supposed to see this '-' ",
  }
} 
