import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
  margin: 0;
  justify-content: space-between;
  padding: 20px 30px;

  button {
    background: none;
    border: none;
  }

  svg {
    path {
      fill-opacity: 1;
      fill: ${({ theme }) => theme.colors.text};
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        fill-opacity: 0.7;

        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const Svg = styled.svg`
  path {
    fill-opacity: 1;
    fill: ${({ theme }) => theme.colors.text};
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      fill-opacity: 0.7;
    }
  }
`;
