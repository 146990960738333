/* eslint-disable react-hooks/exhaustive-deps */
import { useLanguage } from "context/LanguageContext";
import React from "react";

import { useMobile } from "../../hooks/useMobile";

import ContactButton from "./ContactButton";
import LoadImageShimmer from "./LoadImageShimmer";

import {
  Title,
  Content,
  SubTitle,
  Container,
  LeftContainer,
  ImageContainer,
  RightContainer,
} from "./styles";

const About = () => {
  const [loading, setLoading] = React.useState(true);

  const me01IMG = require("../../assets/images/image01.png");
  const me02IMG = require("../../assets/images/image02.png");

  const { isMobile } = useMobile();
  const { language } = useLanguage();

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const ImageComponent = () => (
    <ImageContainer>
      {loading ? (
        <LoadImageShimmer />
      ) : (
        <>
          <img className="fist" src={me01IMG} alt="eu-01" />
          <img className="sec" src={me02IMG} alt="eu-02" />
        </>
      )}
    </ImageContainer>
  );

  return (
    <Container>
      <Content isMobile={isMobile}>
        <LeftContainer>
          <Title>{language.about.title}</Title>

          <SubTitle>
            {language.about.subtitle}
          </SubTitle>

          <ContactButton />
        </LeftContainer>
        <RightContainer>
          {isMobile ? <div /> : <ImageComponent />}
        </RightContainer>
      </Content>
    </Container>
  );
};

export default About;
