import React from "react";
import { useNavigate } from "react-router-dom";
import ProfileCircle from "../../components/ProfileCircle";

import {
  Title,
  Content,
  Container,
  WrapperType,
  LeftContent,
  IconsContainer,
  MobileContainer,
  ChangeLanguage,
} from "./styles";

import { useMobile } from "../../hooks/useMobile";
import usePersistedState from "../../hooks/usePersistedState";
import { useLanguage } from "context/LanguageContext";

const Home = () => {
  const [homeLoaded, setHomeLoaded] = usePersistedState(
    "homeAnimationLoaded",
    false
  );
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const { language, setLanguageSelected } = useLanguage();

  const handleNavigate = (path: string) => {
    return navigate(path);
  };

  const github = "https://www.github.com/4lysson-a";

  React.useEffect(() => {
    setTimeout(() => {
      setHomeLoaded(true);
    }, 5200);
  }, []);

  // return

  const IconsComponents = () => (
    <IconsContainer>
      <a className="div-1" href={github} target="blank">
        <svg
          width="52"
          height="50"
          viewBox="0 0 52 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.9773 0.32168C11.9701 0.316016 0.625 11.6555 0.625 25.6514C0.625 36.7189 7.72207 46.1269 17.6059 49.582C18.9369 49.9162 18.733 48.9703 18.733 48.3246V43.935C11.0469 44.8355 10.7354 39.7492 10.2199 38.8996C9.17773 37.1211 6.71387 36.668 7.45019 35.8184C9.20039 34.9178 10.9846 36.0449 13.052 39.0978C14.5473 41.3125 17.4643 40.9387 18.9426 40.5705C19.2654 39.2394 19.9564 38.05 20.908 37.1267C12.9443 35.6994 9.62519 30.8396 9.62519 25.0623C9.62519 22.2586 10.5484 19.6814 12.3609 17.6027C11.2055 14.176 12.4686 11.242 12.6385 10.8059C15.9293 10.5113 19.3504 13.1621 19.6166 13.3717C21.4857 12.8676 23.6211 12.6014 26.0113 12.6014C28.4129 12.6014 30.5539 12.8789 32.44 13.3887C33.0801 12.9016 36.2519 10.6246 39.3105 10.9021C39.4748 11.3383 40.7096 14.2043 39.6221 17.5857C41.4572 19.6701 42.3918 22.2699 42.3918 25.0793C42.3918 30.868 39.05 35.7334 31.0637 37.1381C31.7477 37.8108 32.2909 38.6131 32.6614 39.498C33.0319 40.383 33.2224 41.333 33.2217 42.2924V48.6644C33.267 49.1742 33.2217 49.6783 34.0713 49.6783C44.1023 46.2969 51.324 36.8209 51.324 25.657C51.324 11.6555 39.9732 0.32168 25.9773 0.32168Z"
            fill="black"
          />
        </svg>
      </a>

      <a className="div-2" href="https://www.linkedin.com/in/4lysson/">
        <svg
          fill="#000000"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          width="50px"
          height="50px"
        >
          {" "}
          <path d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z" />
        </svg>
      </a>

      <a className="div-3" href="mailto:4ly.alcantara@gmail.com">
        <svg
          width="50"
          height="40"
          viewBox="0 0 50 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M44.3333 34.5H39.5V13.3542L25 22.4167L10.5 13.3542V34.5H5.66668V5.5H8.56668L25 15.7708L41.4333 5.5H44.3333V34.5ZM44.3333 0.666664H5.66668C2.98418 0.666664 0.833344 2.8175 0.833344 5.5V34.5C0.833344 35.7819 1.34257 37.0113 2.24899 37.9177C3.15542 38.8241 4.3848 39.3333 5.66668 39.3333H44.3333C45.6152 39.3333 46.8446 38.8241 47.751 37.9177C48.6574 37.0113 49.1667 35.7819 49.1667 34.5V5.5C49.1667 4.21812 48.6574 2.98874 47.751 2.08231C46.8446 1.17589 45.6152 0.666664 44.3333 0.666664Z"
            fill="black"
          />
        </svg>
      </a>

    </IconsContainer>
  );

  const ContentComponent = () => {
    return React.useMemo(
      () => (
        <Content>
          <ProfileCircle />
          <LeftContent isMobile={isMobile}>
            <div>
              <Title homeLoaded={homeLoaded}>
                <h1>
                  <span id="alysson">Alysson</span>
                  <span id="alcantara"> Alcantara</span>
                </h1>
                <div />
              </Title>
              <WrapperType homeLoaded={homeLoaded}>
                <h2 className="typing-demo">
                  <span>Front-end</span> developer.
                </h2>
              </WrapperType>
            </div>
            <div>
              <ul>
                <li>
                  <button
                    type="button"
                    onClick={() => handleNavigate(language.about.path)}
                  >
                    {language.home.buttons.about}
                  </button>
                </li>
                <li>
                  <button disabled onClick={() => handleNavigate("/projetos")}>
                    {language.home.buttons.projects}
                  </button>
                </li>
                {/* <li>
                  <button disabled>Show Time</button>
                </li> */}
              </ul>
              <IconsComponents />
            </div>
          </LeftContent>
          <ChangeLanguage>
            <button
              type="button"
              onClick={() => {
                setLanguageSelected(
                  language.id === "pt-br" ? "en-us" : "pt-br"
                );
              }}
            >
              {language.home.buttons.changedLang}
            </button>
          </ChangeLanguage>
        </Content>
      ),
      []
    );
  };

  if (isMobile) {
    return (
      <MobileContainer>
        <ContentComponent />
      </MobileContainer>
    );
  }

  return (
    <>
      <Container>
        <ContentComponent />
      </Container>
    </>
  );
};

export default Home;
