import styled from "styled-components";
import { fadeInAnimation } from "../../styles/global";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 30px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 30px;
`;
export const RightContainer = styled.div``;

export const Content = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  gap: 10%;
  align-items: center;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.text};
  font-size: 3rem;
  max-width: 500px;
  opacity: 0;
  animation: ${fadeInAnimation} 0.5s ease forwards;
`;

export const SubTitle = styled.h2`
  max-width: 530px;
  opacity: 0.8;
  font-size: 1.1rem;
  opacity: 0;
  animation: ${fadeInAnimation} 0.5s 0.1s ease forwards;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1vw;

  opacity: 0;
  animation: ${fadeInAnimation} 0.6s 0.15s ease forwards;

  .fist {
    opacity: 0;
    animation: ${fadeInAnimation} 0.6s 0.15s ease forwards;
    width: 11vw;
    height: 60vh;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-top: 10vh;
  }

  .sec {
    opacity: 0;
    animation: ${fadeInAnimation} 0.6s 0.25s ease forwards;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    object-fit: cover;
    width: 13vw;
    height: 60vh;
  }
`;
