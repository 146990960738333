export const light = {
  title: "light",

  colors: {
    primary: "#452AEF",
    secondary: "#5F80F8",
    gradient: "linear-gradient(to right, #452AEF , #5F80F8)",
    background: "#f1f1f1",
    text: "#2F2C2C",
  },

  loadingShimmer:
    "linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%)",

  fonts: {
    monospaced: "Fira Code, monospace",
    primary: "Poppins, sans-serif",
  },
};
