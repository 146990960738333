import { LanguageContextProvider } from "context/LanguageContext";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { LoadingContextProvider } from "./context/LoadingContext";

import { ThemeSwitcherProvider } from "./context/ThemeSwitcherContext";
import { TokenContextProvider } from "./context/TokenContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <TokenContextProvider>
      <LanguageContextProvider>
        <ThemeSwitcherProvider>
          <LoadingContextProvider>
            <App />
          </LoadingContextProvider>
        </ThemeSwitcherProvider>
      </LanguageContextProvider>
    </TokenContextProvider>
  </React.StrictMode>
);
