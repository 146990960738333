import React from "react";

import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";

import { TokenContext } from "./context/TokenContext";

import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Projects from "./pages/Projects";
import Header from "./components/Header";

import packageJson from '../package.json';
import ShowVersion from "./pages/ShowVersion/ShowVersion";
import { useLanguage } from 'context/LanguageContext';

const RouterComponent = () => {
  const [hasExpiredToken, setHasExpiredToken] = React.useState(false);
  const { expiredToken } = React.useContext(TokenContext);
  const { redirectByLang } = useLanguage();
  const version = packageJson.version;

  function verifyIfExpiredTokenDate(date: Date) {
    const currentDate = new Date();
    const expireDate = new Date(date);
    return currentDate > expireDate;
  }

  React.useEffect(() => {
    const value = verifyIfExpiredTokenDate(expiredToken);
    if (value) {
      setHasExpiredToken(true);
    } else {
      setHasExpiredToken(false);
    }
  }, [expiredToken]);

  React.useEffect(() => {
    if (hasExpiredToken) {
      localStorage.removeItem("expireToken");
      localStorage.removeItem("picLoad");
      localStorage.removeItem("homeAnimationLoaded");
    }
  }, [hasExpiredToken]);


  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to={redirectByLang} />} />

        {/* main routes */}
        <Route path="/inicio" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/about" element={<About />} />
        <Route path="/projetos" element={<Projects />} />
        <Route path="/projects" element={<Projects />} />

        {/* Version */}
        <Route path="/ver" element={<ShowVersion version={version} />} />
        <Route path="/version" element={<Navigate to="/ver" replace />} />

        {/* Not found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default RouterComponent;
