import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    transform: translateX(0);
  } 
  50% {
    transform: translateX(5px);
  }
  100%{
    transform: translateX(0);
  }
`;

const buttonAnimationTime = "0.3s";
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  transition: ${buttonAnimationTime};
  margin-top: 20px;

  cursor: pointer;

  p {
    transition: ${buttonAnimationTime};
    font-weight: medium;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
  }

  &:hover {
    circle {
      transition: ${buttonAnimationTime};
      fill: ${({ theme }) => theme.colors.primary};
    }

    p {
      color: ${({ theme }) => theme.colors.primary};
    }

    .line {
      border-top: 2px solid ${({ theme }) => theme.colors.primary};
    }

    .arrow {
      transform: translateX(5px);
    }
  }
`;

export const Line = styled.div`
  transition: ${buttonAnimationTime};
  opacity: 0.3;
  border-top: 2px solid ${({ theme }) => theme.colors.text};
  width: 50px;
`;

export const Circle = styled.div`
  margin-top: 10px;

  svg {
    transition: ${buttonAnimationTime};
    cursor: pointer;
    width: 35px;
    circle {
      transition: ${buttonAnimationTime};
      fill: ${({ theme }) => theme.colors.gradient};
    }

    .arrow {
      animation: ${pulseAnimation} 0.8s infinite ease forwards;
      transition: ${buttonAnimationTime};
    }

    &:hover {
      .arrow {
        transform: translateX(5px);
      }
    }
  }
`;
