import React, { useCallback, useMemo } from "react";

interface ILoadingContext {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const LoadingContext = React.createContext<ILoadingContext>({
  loading: true,
  setLoading: () => {},
});

export const LoadingContextProvider = ({ children }: any) => {
  const [loading, setLoading] = React.useState<boolean>(true);

  const handleLoading = useCallback((newLoading: boolean) => {
    setLoading(newLoading);
  }, []);

  setTimeout(() => {
    handleLoading(false);
  }, 1500);

  const value = useMemo(() => {
    return { loading, setLoading: handleLoading };
  }, [loading, handleLoading]);

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};
